
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { exitFromFlow, activeFlow } from '@/api/flowSession';
import BaseMenuComponent from '@/components/baseComponents/BaseMenuComponent.vue';

@Component({
  components: {
    BaseMenuComponent,
  },
  computed: {
    ...mapState('user', ['isPortalPageVisited']),
    ...mapGetters('user', ['isAuthenticated']),
  },
})
export default class TheHeader extends Vue {
  public isPortalPageVisited!: boolean;
  public isAuthenticated!: boolean;

  get isShowCloseButton(): boolean {
    const forbiddenPages = [
      'LoginPage',
      'RegistrationPage',
      'ForgotPasswordPage',
      'RestorePasswordPage',
      'CreateAccountPage',
      'NamePage',
      'PortalPage',
    ];
    const routeName = this.$route.name as string;

    return !forbiddenPages.includes(routeName) && this.isPortalPageVisited;
  }

  async closeAction(): Promise<void> {
    const { data } = await activeFlow();
    if (data) await exitFromFlow();
    this.$router.push({ name: 'PortalPage' });
  }
  logoAction(): void {    
    if (this.isAuthenticated && this.$route.name === 'NamePage') {
      return
    }

    if (this.isAuthenticated) {
      this.$router.push({ name: 'PortalPage' });
    }  else this.$router.push({ name: 'HomePage' });
  }
}

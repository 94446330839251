
import { Vue, Component } from 'vue-property-decorator';
import { endTracking } from '@/utils/tracking';
import TheHeader from '@/components/TheHeader.vue';
// import Notifications from 'vue-notification';

export type IMainLayoutClass = ({ portal: boolean; } | { component: boolean; } | { auth: boolean; })[]

@Component({
  components: {
    TheHeader,
  },
})
export default class MainLayout extends Vue {
  get inPortal(): boolean {
    return this.$route.path === '/portal';
  }
  get inComponent(): boolean {
    return this.$route.path === '/cash-management'
      || this.$route.path === '/credit-score'
      || this.$route.path === '/business-valuation'
      || this.$route.path === '/carbon-footprint';
  }
  get isAuth(): boolean {
    return this.$route.path === '/login' || this.$route.path === '/sign-up';
  }
  get headerBackgroundColor(): string {
    return this.$route.path === '/portal' ? 'gradient-background' : '';
  }
  get mainLayoutClass(): IMainLayoutClass {
    return [
      { portal: this.inPortal },
      { component: this.inComponent },
      { auth: this.isAuth },
    ];
  }

  beforeDestroy(): void {
    endTracking();
  }
}
